<template>
  <div>
    <Popup v-bind:enabled="this.status == 'failed'" v-on:close="clean" />
    <Spinner v-if="this.status == 'check'" />
    <div id="AuthForm" v-if="this.status == 'ready'">
      <form @submit.prevent="onSubmit">
        <div>
          <input name="login" type="text" size=30 placeholder="email" v-model='login' />
        </div>
        <div>
          <input name="password" type="password" size=30 placeholder="password" v-model='password' />
        </div>
        <button type="submit">login</button>
      </form>
    </div>
  </div>
</template>

<script>
import Api from '@/utils/api'
import Popup from './Auth/Popup'
import Spinner from '@/components/Spinner'

export default {
  name: 'Auth',
  data() {
    return {
      status:   'check',
      login:    '',
      password: ''
    }
  },
  mounted() {
    Api.get("auth").then((r) => {
      if (r.status != 200) {
        this.status = 'ready'
      } else {
        this.$emit('login', r.json)
      }
    })
  },
  methods: {
    onSubmit() {
     if (this.status == 'failed')
        return 

      this.status = 'check'

      const data = {
        login: this.login,
        password: this.password
      }

      Api.post("auth", data, { json: true }).then((r) => {
          if (r.ok) {
            return r.json()
          } else {
            this.status = 'failed'
          }

          return {}
        })
        .then((data) => {
          this.status = 'check'
          this.$emit('login', data)
        })

    },
    clean() {
      this.status = 'ready'
      this.login = ''
      this.password = ''
    }
  },
  components: {
    Popup,
    Spinner
  }
}
</script>

<style scoped>

#AuthForm {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  width: 300px;
  display: table;
  --tw-bg-opacity: 1;
  background-color: rgba(17,24,39,var(--tw-bg-opacity));
  box-shadow: 0 0 5px rgb(0 0 0 / 70%);
  color: white;
  border-radius: 0.25rem;
}

#AuthForm div {
  line-height: 3rem;
}

#AuthForm div > input {
  padding: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 100%
}

@media screen and (max-width: 700px){
}
</style>
