import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/posts',
      component: () => import('./views/Posts.vue')
    },
    {
      path: '/posts/create',
      component: () => import('./views/Posts/Edit.vue')
    },
    {
      path: '/posts/:id',
      component: () => import('./views/Posts/Edit.vue'),
      props: true
    },
    {
      path: '/docs',
      component: () => import('./views/Test.vue')
    },
    {
      path: '/cabinet',
      component: () => import('./views/Cabinet.vue')
    },
  ]
})
