<template>
  <div id="app" @mouseenter="mouseMove" @mousemove="mouseMove" @mouseleave="mouseMove">
    <div id="main" v-if="this.session">
      <Sidebar />
      <div class="content">
        <router-view />
      </div>
    </div>
    <Auth v-else="this.session" v-on:login="Login" />
  </div>
</template>

<script>
import Auth from '@/components/Auth'
import Sidebar from '@/components/Sidebar'
import Api from '@/utils/api'

export default {
  name: 'App',
  data() {
    return {
      session: null,
      activity: {
        lastNotify: Date.now(),
        eventProcId: null
      },
      user: {
        login: 'mitya',
        role: 'admin'
      }
    }
  },
  mounted() {
    Api.addHook((s) => {
      if (this.session) {
        this.Logout()
      }
    })
    this.mouseMove()
    setInterval(() => {
      if(!this.session) {
        return
      }

      Api.get("auth")
    }, 5000)
  },
  components: {
    Auth,
    Sidebar,
  },
  methods: {
    Login: function(session) {
      this.session = {
        token: session.token,
        expireAt: session.time_till
      }
    },
    Logout: function() {
      console.log("Logout() itself")
      this.session = null
      this.$cable.connection.disconnect()
    },
    mouseMove: function(e) {
      if(!this.session) {
        return
      }

      // avoid sending more than 5 seconds
      if (Date.now() - this.activity.lastNotify < 5000) {
        return
      }

      this.activity.lastNotify = Date.now()
      Api.put("auth")
    }
  }
}
</script>

<style scoped>
body {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-width: 599px;
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgba(209,213,219,var(--tw-bg-opacity));
}

#app {
  font-family: system-ui,-apple-system,Helvetica,Segoe UI,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: calc(100% - 1rem);
}

#main {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 11rem;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 700px) {
  .content {
    margin-left: 5rem;
  }
}
</style>
