export default class Api {
  static getInstance() {
    if (!this._instance) {
      this._instance = new Api
    }

    return this._instance
  }

  static async get(uri, params = {}) {
    var url;
    const query = new URLSearchParams(params).toString()

    if (query.size > 0) {
      url = "/api/v1/admin/" + uri + "?" + query.toString()
    } else {
      url = "/api/v1/admin/" + uri
    }

    return await Api.request(url)
  }

  static async post(uri, data, { json = true }) {
    const url = "/api/v1/admin/" + uri

    return await Api.request(url, {
      method: 'POST',
      headers: json ? { 'Content-Type': 'application/json' } : {},
      body: json ? JSON.stringify(data) : data // body data type must match "Content-Type" header
    })
  }

  static async put(uri, data) {
    const url = "/api/v1/admin/" + uri

    return await Api.request(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
  }

  static async delete(uri, data = {}) {
    const url = "/api/v1/admin/" + uri

    return await Api.request(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
  }

  static async request(url, params = {}) {
    const result = fetch(url, params).then((response) => {
      if (response.status == 401) {
        Api.getInstance().catchStatus(response.status)
      }

      return response
    })

    return await result;
  }

  static addHook(cb) {
    Api.getInstance()._hooks.push(cb)
  }

  constructor() {
    this._hooks = new Array
  }

  catchStatus(status) {
    this._hooks.forEach((cb) => { cb(status) })
  }
}
